<template>    
    <seccion-datos :titulo="laboral" tipo="success" @cambios="guardar()">
                <div class="form-group">
                    <label for="horas_convenio_dia">{{ $t('general.horasconveniodia') }}</label>
                    <input type="number" class="form-control dato horas_convenio_dia" v-model="horas_convenio_dia" id="horas_convenio_dia" placeholder="Horas por convenio al día" >
                </div>
                <div class="form-group">
                    <label for="jornada_laboral">{{ $t('general.expedientesactivos') }}</label>
                    <input type="text" class="form-control dato jornada_laboral" id="jornada_laboral" v-model="jornada_laboral" placeholder="Tipo de jornada laboral">
                </div>
        </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default {
    props: ['usuario'],
    data() {
        return {
            horas_convenio_dia: '',
            jornada_laboral: '',
            id: '',
            laboral:'',
        }
    },
    methods: {
        cargardatos() {
            this.$store.dispatch('cambiarCargando', true);
            this.horas_convenio_dia = this.usuario.horas_convenio_dia;
            this.jornada_laboral = this.usuario.jornada_laboral;
            this.id = this.usuario.id;
            this.$store.dispatch('cambiarCargando', false);
            this.laboral = this.$t('general.laboral');

        },
        async guardar() {
            try {
                this.$store.dispatch('cambiarGuardando', true);
                const api = new PwgsApi;
                const subidadatos = { horas_convenio_dia: this.horas_convenio_dia, jornada_laboral: this.jornada_laboral };
                await api.put('usuarios/' + this.id, subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                this.$store.dispatch('cambiarGuardando', false);
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
            }
        },
    }, 
    watch: {
        usuario() {
            this.cargardatos();
        }
    }
}
</script>